<template>
  <el-dialog :append-to-body="true" :visible.sync="dialog" :title="editable ? '调价申请' : '价格策略明细'" @closed="cancel" :close-on-click-modal="false" width="840px">
    <template v-if="detail">
      <form-info-item label="调价商品：" label-position="right" label-width="auto">
        <b>{{detail.name}}</b>
      </form-info-item>
      <el-tabs v-model="currentTab" v-loading="loading">
        <el-tab-pane label="批发价" name="basic">
          <template v-if="editable">
            <el-button size="mini" type="info" icon="el-icon-plus" @click.stop="handleAddBasic">添加价格</el-button>
            <el-table :data="basics" size="mini" empty-text="还没有批发价的调价申请">
              <el-table-column label="起订量" width="120">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.modMin" :min="1" :step="1" :precision="0" size="small" controls-position="right" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column label="价格" width="150">
                <template slot-scope="scope">
                  <price-input :min="0" v-model="scope.row.goodsPrice" size="small" />
                </template>
              </el-table-column>
              <el-table-column label="日期" min-width="200">
                <template slot-scope="scope">
                  <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="50">
                <div class="row-commands" slot-scope="scope">
                  <el-button size="mini" type="text" @click="remove(scope.row,0)">移除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </template>
          <template v-else>
            <el-table :data="basics" empty-text="没有批发价的价格策略">
              <el-table-column prop="modMin" label="起订量" width="120" />
              <el-table-column prop="goodsPrice" label="价格" width="150" :formatter="$price" />
              <el-table-column label="日期">
                <template slot-scope="scope">{{new Date(scope.row.beginTime).format("yyyy年M月d日")}}至{{new Date(scope.row.endTime).format("yyyy年M月d日")}}</template>
              </el-table-column>
            </el-table>
          </template>
        </el-tab-pane>
        <el-tab-pane label="经销商类型" name="level">
          <template v-if="editable">
            <datagrid-picker reference="选择经销商类型" reference-type="info" url="api/distributorLeve" :popper-width="400" @change="handleAddTarget">
              <el-table-column prop="name" label="经销商类型" min-width="160" />
            </datagrid-picker>

            <el-table :data="levels" size="mini" empty-text="还没有针对经销商类型的调价申请">
              <el-table-column label="经销商类型" prop="distributorLeveName" min-width="160" />
              <el-table-column label="起订量" width="120">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.modMin" :min="1" :step="1" :precision="0" size="small" controls-position="right" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column label="价格" width="150">
                <template slot-scope="scope">
                  <price-input :min="0" v-model="scope.row.goodsPrice" size="small" />
                </template>
              </el-table-column>
              <el-table-column label="日期" min-width="200">
                <template slot-scope="scope">
                  <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="40">
                <div class="row-commands" slot-scope="scope">
                  <el-button size="mini" type="text" @click="remove(scope.row,1)">移除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </template>
          <template v-else>
            <el-table :data="levels" empty-text="没有针对经销商类型的价格策略">
              <el-table-column label="经销商类型" prop="distributorLeveName" width="160" />
              <el-table-column prop="modMin" label="起订量" width="120" />
              <el-table-column prop="goodsPrice" label="价格" width="150" :formatter="$price" />
              <el-table-column label="日期">
                <template slot-scope="scope">{{new Date(scope.row.beginTime).format("yyyy年M月d日")}}至{{new Date(scope.row.endTime).format("yyyy年M月d日")}}</template>
              </el-table-column>
            </el-table>
          </template>
        </el-tab-pane>
        <el-tab-pane label="指定经销商" name="target">
          <template v-if="editable">
            <datagrid-picker reference="选择经销商" reference-type="info" url="api/distributor" :query-define="distributorQueryDefine" :popper-width="800" @change="handleAddLevel">
              <div class="padding-10 bc-l" slot="banner" slot-scope="scope">
                <el-input :maxlength="20" v-model="distributorQueryDefine.name" @keypress.enter.native="scope.query" @clear="scope.query" :validate-event="false" clearable placeholder="输入经销商名称进行搜索" style="width: 240px;">
                  <el-button icon="el-icon-search" slot="append" @click.stop="scope.query" />
                </el-input>
              </div>
              <el-table-column prop="erpId" label="ERP编码" min-width="80" />
              <el-table-column prop="name" label="经销商" min-width="160" />
              <!-- <el-table-column label="经销商类型" width="160" :formatter="r => {return getName(r.level);}" /> -->
              <el-table-column label="所在地区" min-width="200" :formatter="formatDistributorArea" />
            </datagrid-picker>
            <el-table v-loading="loading" :data="targets" size="small" style="width: 100%;" empty-text="还没有针对指定经销商的调价申请">
              <el-table-column label="经销商" prop="distributorName" min-width="160" show-overflow-tooltip />
              <el-table-column label="起订量" width="120">
                <template slot-scope="scope">
                  <el-input-number v-model="scope.row.modMin" :min="1" :step="1" :precision="0" size="small" controls-position="right" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column label="价格" width="150">
                <template slot-scope="scope">
                  <price-input :min="0" v-model="scope.row.goodsPrice" size="small" />
                </template>
              </el-table-column>
              <el-table-column label="日期" min-width="200">
                <template slot-scope="scope">
                  <el-date-picker type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="timestamp" format="yyyy/MM/dd" v-model="scope.row._dateRange" :default-time="['00:00:00', '23:59:59']" style="width: 100%;" />
                </template>
              </el-table-column>
              <el-table-column fixed="right" width="40">
                <div class="row-commands" slot-scope="scope">
                  <el-button size="mini" type="text" @click="remove(scope.row, 2)">移除</el-button>
                </div>
              </el-table-column>
            </el-table>
          </template>
          <template v-else>
            <el-table :data="targets" empty-text="没有针对指定经销商的价格策略">
              <el-table-column label="经销商" prop="distributorName" width="160" />
              <el-table-column prop="modMin" label="起订量" width="120" />
              <el-table-column prop="goodsPrice" label="价格" width="150" :formatter="$price" />
              <el-table-column label="日期">
                <template slot-scope="scope">{{new Date(scope.row.beginTime).format("yyyy年M月d日")}}至{{new Date(scope.row.endTime).format("yyyy年M月d日")}}</template>
              </el-table-column>
            </el-table>
          </template>
        </el-tab-pane>
      </el-tabs>

      <div slot="footer" v-if="editable">
        <el-button type="text" @click="dialog=false">取消</el-button>
        <el-button :loading="saving" type="primary" @click="handleSave">保存</el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script>
import { add } from "@/api/adjust";
import request from "@/utils/request";

export default {
  data() {
    return {
      currentTab: "basic",
      dialog: false,
      editable: false,
      saving: false,
      loading: false,
      distributorQueryDefine: {
        name: ""
      },
      detail: null,
      basics: [],
      levels: [],
      targets: []
    };
  },
  methods: {
    formatDistributorArea(row) {
      let as = [];
      if (row.provinceName) as.push(row.provinceName);
      if (row.cityName) as.push(row.cityName);
      if (row.districtName) as.push(row.districtName);
      return as.join("/");
    },
    remove(row, priceType) {
      let source =
        priceType === 0
          ? this.basics
          : priceType === 1
          ? this.levels
          : this.targets;
      let inx = source.findIndex(o => {
        return o._id === row._id;
      });
      if (inx >= 0) {
        source.splice(inx, 1);
      }
    },
    handleAddBasic() {
      this.basics.push({
        _id: this.$uuid(),
        priceType: 0,
        goodsPrice: null,
        beginTime: null,
        endTime: null,
        modMin: 1,
        _dateRange: []
      });
    },
    handleAddLevel(v, d, data) {
      this.targets.push({
        _id: this.$uuid(),
        priceType: 2,
        distributorId: v,
        distributorName: data.name,
        goodsPrice: null,
        beginTime: null,
        endTime: null,
        modMin: 1,
        modMax: null,
        _dateRange: []
      });
    },
    handleAddTarget(v, d, data) {
      this.levels.push({
        _id: this.$uuid(),
        priceType: 1,
        distributorLeve: v,
        distributorLeveName: data.name,
        goodsPrice: null,
        beginTime: null,
        endTime: null,
        modMin: 1,
        modMax: null,
        _dateRange: []
      });
    },
    cancel() {
      this.currentTab = "basic";
      this.detail = null;
      this.basics = [];
      this.levels = [];
      this.targets = [];
    },
    handleSave() {
      let err = false,
        arr = [];
      if (this.basics && this.basics.length) {
        for (let i = 0, l = this.basics.length; i < l; i++) {
          let o = this.basics[i];
          if (
            !o.modMin ||
            !o.goodsPrice ||
            !o._dateRange ||
            o._dateRange.length !== 2
          ) {
            err = true;
            this.currentTab = "basic";
            break;
          } else {
            o.goodsId = this.detail.goodsId || this.detail.id;     
            o.goodsType = this.detail.goodsType;       
            o.beginTime = o._dateRange[0];
            o.endTime = o._dateRange[1];        
            arr.push(o);
          }
        }
      }

      if (!err && this.levels && this.levels.length) {
        for (let i = 0, l = this.levels.length; i < l; i++) {
          let o = this.levels[i];
          if (
            !o.modMin ||
            !o.goodsPrice ||
            !o._dateRange ||
            o._dateRange.length !== 2
          ) {
            err = true;
            this.currentTab = "level";
            break;
          } else {
            o.goodsId = this.detail.goodsId || this.detail.id;
            o.goodsType = this.detail.goodsType;
            o.beginTime = o._dateRange[0];
            o.endTime = o._dateRange[1];
            arr.push(o);
          }
        }
      }

      if (!err && this.targets && this.targets.length) {
        for (let i = 0, l = this.targets.length; i < l; i++) {
          let o = this.targets[i];
          if (
            !o.modMin ||
            !o.goodsPrice ||
            !o._dateRange ||
            o._dateRange.length !== 2
          ) {
            err = true;
            this.currentTab = "target";
            break;
          } else {
            o.goodsId = this.detail.goodsId || this.detail.id;
            o.goodsType = this.detail.goodsType;
            o.beginTime = o._dateRange[0];
            o.endTime = o._dateRange[1];
            arr.push(o);
          }
        }
      }

      if (!err && arr.length) {
        this.saving = true;
        add(arr)
          .then(res => {
            this.$message({
              type: "success",
              message: "调价申请成功!"
            });
            this.saving = false;
            this.$parent.init();
            this.dialog = false;
          })
          .catch(err => {
            this.saving = false;
          });
      } else {
        this.$alert("请填写至少一个调价并且确保内容填写完整。", "系统提示");
      }
    },
    resetForm(detail, editable = false) {
      console.log(detail);
      this.detail = detail;
      this.editable = editable;
      this.dialog = true;
      if (!editable) {
        this.loading = true;
        request({
          url:
            "api/distributorGoodsPriceDetail/" +
            detail.goodsId +
            "?entId=" +
            detail.entId,
          method: "get"
        })
          .then(res => {
            (res || []).forEach(r => {
              r._dateRange = [r.beginTime, r.endTime];
              if (r.priceType === 0) {
                this.basics.push(r);
              }
              if (r.priceType === 1) {
                this.levels.push(r);
              }
              if (r.priceType === 2) {
                this.targets.push(r);
              }
            });
          })
          .finally(_ => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
